import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
 

const FlexGiftCard = (props) => {
  return (
    <div className="flexgiftcard">
      <div className={props.rowType}>
        <div className="col">
          <div className="card__image">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="card__content">
            <h3>{props.header}</h3>
            <h6>{props.subheader}</h6>
            <p>{props.text}</p>
            {props.children}
           {props.url &&  <a href={props.url}><button className="btn btn-black">{props.btnText}</button></a>}
           {props.phone && <a href={props.phone} target="_blank" rel="noopener noreferrer" className='phone' ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></a> }
           {props.externalURL &&  <a href={props.externalURL} target="_blank" rel="noreferrer noopener"><button className="btn btn-black">{props.btnText}</button></a>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FlexGiftCard;